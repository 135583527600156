
require("jquery-ui/ui/widget")
require("jquery-ui/ui/position")
require("jquery-ui/ui/widgets/dialog")
require("jquery-ui/ui/widgets/tabs")
require("jquery-ui/ui/widgets/datepicker")

const moment = require("moment")
var templateUrl='https://www.freetobook.com/resale/templates/standard_wts';
var maxNights = 21; // default - should be overridden on page load

const MAX_CHILD_AGE = 17

// These are the filter options on the main search form that have 'units' instead of rooms
const FILTER_TYPES_WITH_UNITS = ["Self-catering options", "Camping & Caravan"]

$(function() {
  maxNights = Number($('#duration_input option').length);

  var today = moment().format("YYYY-MM-DD");
  var tomorrow = moment().add(1, 'days').format("YYYY-MM-DD");

  $("#check_in_date").val(today);
  $("#check_out_date").val(tomorrow);

  $.fn.populateCheckIn = function() {
      for(var i = 0; i < 364; i++){
          var date = moment().add(i, 'days').format("YYYY-MM-DD");
          var formattedDate = moment().add(i, 'days').format("DD MMM YYYY (ddd)");

          $(this).append('<option value="'+date+'">'+formattedDate+'</option>');
      }
  };

  $.fn.populateCheckOut = function() {
      for(var i = 1; i < 365; i++){
          var date = moment().add(i, 'days').format("YYYY-MM-DD");
          var formattedDate = moment().add(i, 'days').format("DD MMM YYYY (ddd)");

          $(this).append('<option value="'+date+'">'+formattedDate+'</option>');
      }
  };

  $('#check_in_select').populateCheckIn();
  $('#check_out_select').populateCheckOut();

  initDatepickers();

    // update checkout on checkin date change
  $('#check_in_select').change(checkInChangeHandler);

  // update duration on checkout date change + warn if the duration is excessive
  $('#check_out_select').change(checkOutChangeHandler);

  // update checkout value with duration change
  $('#duration_input').change(stayLengthChangeHandler);

  $("img.ui-datepicker-trigger").bind('mouseover',function() {
  $(this).attr('src' , templateUrl + '/images/searchForm/calendar_over.png');
  });

  $("img.ui-datepicker-trigger").bind('mouseout',function() {
  $(this).attr('src' , templateUrl + '/images/searchForm/calendar.png');
  });

  $('#errorDialogues div').dialog({
  autoOpen : false,
  modal : true,
  width : 235,
  resizable : false,
  buttons: { Ok: function() { $( this ).dialog( "close" ); } }
  });

  set_num_rooms();

  var number_of = $("#number_of").text();

  function displayCustomName() {
    let selectedRegion = $(".region-selector option:selected").text().trim();
    if (FILTER_TYPES_WITH_UNITS.includes(selectedRegion)) {
      $("#number_of").text("Number of Units");
      draw_rooms();
    } else {
      $("#number_of").text(number_of);
      draw_rooms();
    }
  }
  $(".region-selector").change(displayCustomName);
});

window.set_num_rooms = function()
{
  var numRooms = $('#num_rooms').val();
  if ( numRooms<rooms.length )
  {
  rooms = rooms.slice(0,numRooms);
  }
  else
  {
  for ( var i=rooms.length; i<numRooms; i++ )
  {
  rooms[i] = new Array(2,0,new Array());
  }
  }
  draw_rooms();
}

window.set_adults = function( roomNum )
{
  rooms[roomNum][0] = $('#room_'+roomNum+'_adults').val();
}

window.set_children = function( roomNum )
{
  var numChildren = $('#room_'+roomNum+'_children').val()
  rooms[roomNum][1] = numChildren;

  if ( numChildren<rooms[roomNum][2].length )
  {
  rooms[roomNum][2].splice(0,numChildren);
  }
  else
  {
  for ( var i=rooms[roomNum][2].length; i<numChildren; i++ )
  {
  rooms[roomNum][2][i] = 0;
  }
  }

  draw_rooms();
}

window.set_age = function( roomNum, childNum )
{
  rooms[roomNum][2][childNum] = $('#room_'+roomNum+'_age_'+childNum).val()
}

// initialises datepicker (calendar) for both check in and check out
function initDatepickers()
{
  var minCheckIn = Number($('#search-form input[name=mincheckin]').val());
  var maxCheckIn = Number($('#search-form input[name=maxcheckin]').val());


  $("#search-form input.staydate").datepicker({
  showAnim : 'fadeIn',
  showOn : 'button',
  buttonImage : templateUrl + '/images/searchForm/calendar.png',
  buttonImageOnly : true,
  showButtonPanel: true,
  dateFormat: 'yy-mm-dd',
  changeMonth : true,
  changeYear : true
  });

  $('#check_in_date').datepicker('option', {
  minDate : minCheckIn,
  maxDate : maxCheckIn,
  onSelect : function(date) { $('#check_in_select').val(date).change(); }
  });

  var maxNights = Number($('#duration_input option').length);
  var checkIn = $('#check_in_select').val();
  var dateArr = checkIn.split('-');
  var ciTimestamp = Date.UTC(dateArr[0], dateArr[1]-1, dateArr[2]);

  var maxCoDateObj = new Date(ciTimestamp + (86400000 * maxNights));



  $('#check_out_date').datepicker('option', {
  minDate : (minCheckIn+1),
  maxDate : maxCoDateObj,
  onSelect : function(date) { $('#check_out_select').val(date).change(); }
  });
}


function checkInChangeHandler()
{
  var checkIn = $(this).val();
  var dateArr = checkIn.split('-');
  var numNights = Number($('#duration_input').val());
  var maxNights = Number($('#duration_input option').length);

  var ciTimestamp = Date.UTC(dateArr[0], dateArr[1]-1, dateArr[2]);
  var coDateObj = new Date(ciTimestamp + (numNights * 86400000));
  var month = coDateObj.getUTCMonth() + 1;
  var day = coDateObj.getUTCDate();
  var checkOut = coDateObj.getUTCFullYear() + '-';
  checkOut += ((month < 10) ? '0' + month : month) + '-';
  checkOut += (day < 10) ? '0' + day : day;
  var minCoDateObj = new Date(ciTimestamp + 86400000);
  var maxCoDateObj = new Date(ciTimestamp + (86400000 * maxNights));

  $('#check_in_date').val(checkIn);

  $('#check_out_date').datepicker('option', {
  minDate : minCoDateObj,
  maxDate : maxCoDateObj,
  });
  $('#check_out_select').val(checkOut).change();
}

function checkOutChangeHandler()
{
  var dateArr;

  var checkOut = $(this).val();
  var checkIn = $('#check_in_date').val();

  dateArr = checkOut.split('-');
  var coTimestamp = Date.UTC(dateArr[0], dateArr[1]-1, dateArr[2]);
  dateArr = checkIn.split('-');
  var ciTimestamp = Date.UTC(dateArr[0], dateArr[1]-1, dateArr[2]);
  var numNights = (coTimestamp - ciTimestamp) / 86400000;
  var maxNights = Number($('#duration_input option').length);
  if ((numNights > 0) && (numNights <= maxNights))
  {
  $('#check_out_date').val(checkOut);
  $('#duration_input').val(numNights);
  }
  else
  {
  var errorDialogue = (numNights > 0) ? '#checkOutTooManyNightsError' : '#checkOutPreCheckinError';
  $(errorDialogue).dialog('open');
  checkOut = $('#check_out_date').val();
  $(this).val(checkOut);
  }
}

function stayLengthChangeHandler()
{
  var numNights = Number($(this).val());
  var checkIn = $('#check_in_date').val();

  var dateArr = checkIn.split('-');
  var ciTimestamp = Date.UTC(dateArr[0], dateArr[1]-1, dateArr[2]);

  var coDateObj = new Date(ciTimestamp + (numNights * 86400000));
  var month = coDateObj.getUTCMonth() + 1;
  var day = coDateObj.getUTCDate();
  var checkOut = coDateObj.getUTCFullYear() + '-';
  checkOut += ((month < 10) ? '0' + month : month) + '-';
  checkOut += (day < 10) ? '0' + day : day;

  $('#check_out_select').val(checkOut).change();
}

var rooms = new Array();

function draw_rooms()
{
  if ($.inArray($(".region-selector option:selected").text().trim(), ["Self-catering", "Camping & Caravan"]) > -1) {
  unitName='Unit';
  }
  else {
  unitName='Room';
  }

  html = '';
  for ( var i=0; i<rooms.length; i++ )
  {
  if ( rooms.length>1 )
  {
  html += '<div class="room-number">' + unitName +' '+(i+1)+'</div>';
  }
    html += `<div id="rooms" class="rooms-row row">`
  html += `
      <div class="col-12 col-sm-6">
        <label for="room_${i}_adults">Adults</label>
        <select class="form-control"
                name="room_${i}_adults"
                id="room_${i}_adults"
                onchange="set_adults(${i})">`;

  for ( var j=1; j<17; j++ )
  {
  html += '<option value="' + j + '"' + (j==rooms[i][0] ? ' selected="selected"' : '') + '>' + j + '</option>';
  }
  html += '</select></div>';

  html += `
      <div class="col-12 col-sm-6">
        <label for="room_${i}_children">Children</label>
        <select class="form-control"
                name="room_${i}_children"
                id="room_${i}_children"
                onchange="set_children(${i})">
      `;

  for ( var j=0; j<3; j++ )
  {
  html += '<option value="' + j + '"' + (j == rooms[i][1] ? ' selected="selected"' : '') + '>' + j + '</option>';
  }
  html += '</select>';
  html += '</div></div>';

  if ( rooms[i][1]>0 )
  {
  html += '<div class="rooms-row row">';

  for ( var k=0; k<rooms[i][1]; k++ )
  {
        let childTotal = rooms[i][1];
        let secondChild = k > 0;
        console.log("secondChild:", secondChild);
        let labelText = secondChild ? 'and' : ('Child Age' + (childTotal > 1 ? 's' : ''))
  html += `
          <div class="col-12 col-sm-6">
            <label for="room_${i}_age_${k}">
              ${labelText}
            </label>
            <select class="form-control"
                    name="room_${i}_age_${k}"
                    id="room_${i}_age_${k}"
                    onchange="set_age(${i}, ${k})">`;
  for ( var l=0; l <= MAX_CHILD_AGE; l++ )
  {
  html += '<option value="'+l+'"'+
  (l==rooms[i][2][k]?' selected="selected"':'')
  +'>'+l+'</option>';
  }
  html += '</select></div>';
  }

  html += '</div>';
  }

  html += '</div>';
  }

  html += '</div>';

  $('.room-row-container').html(html);
}
